body {
    background-color: #F5F9F6 !important;
    font: normal 0.9em/1.5 Arial,Helvetica,sans-serif;
    max-width: none !important;
}

.menu-logo-wrapper{
    display: flex;
    justify-content: center;
    padding-top: 30px;
    margin-bottom: 30px;
}

.menu-logo{
    width: 150px;
}

.menu{
    width: 70px;
    height: 100%;
    background-color: #343A40;
    color: white;
    /*left: 260px;*/
    position: fixed;
    /*margin-left: -100px;*/
    margin-top: 20px;
    /*left: 100px;*/
    /*overflow-y: auto;*/
    /*z-index: 1000;*/
    transform:translate(0px);
    transition: all 500ms ease-in-out;
    z-index: 1000;
}

.menu-active{
    width: 650px;
    height: 100%;
    background-color: #343A40;
    color: white;
    /*left: 260px;*/
    position: fixed;
    /*margin-left: -100px;*/
    margin-top: 20px;
    /*left: 100px;*/
    /*overflow-y: auto;*/
    /*z-index: 1000;*/
    transform:translate(0px);
    transition: all 500ms ease-in-out;
    z-index: 2000;
}

.menu-title{
    font-weight: 900;
    font-size: 14px;
    padding-top: 40px;
    padding-bottom: 10px;
}

.menu ul {
    list-style-type: none;
}

.menu li{
    font-size: 14px;
    padding-bottom: 10px;
    text-decoration: none;
}

.menu-icon-container {
    min-width: 50px;
    /*border: 1px solid white;*/
}

.menu-icon{
    font-size: 30px;
}

.menu-option-text-active{
    margin-left: 30px;
    /*border: 1px solid white;*/
    min-width: 600px;
    height: 20px;
    /*margin-top: 30px;*/
    transform:translate(0px);
    transition: all 500ms ease-in-out;
    opacity: 1;
    transition: transfom opacity;
}

.menu-option-text{
    margin-left: 30px;
    /*border: 1px solid white;*/
    min-width: 0px;
    width: 0px;
    height: 20px;
    overflow: hidden;
    /*margin-top: 30px;*/
    transform:translate(0px);
    transition: all 500ms ease-in-out;
    opacity: 0;
    transition: transfom opacity;
}

.menu-option-text-hover{
    margin-top: 20px;
    transform:translate(0px);
    transition: all 500ms ease-in-out;
    opacity: 1;
    transition: transfom opacity 
}

.menu-option-wrapper{
    display: flex;
    flex-direction: row;
    /*justify-content: center;*/
    align-items: center;
    margin-bottom: 35px;
    margin-left: 22px;
}

.home-wrapper{
    /*padding-left: 70px;*/
    left: 0px;
}

.home-logo-container {
    padding-top: 60px;
    padding-left: 110px;
}

.home-logo {
    width: 300px;
    /*background-color: var(--primary-color);*/
    padding: 20px;
    border-radius: 5px;
}

.home-main {
    padding-top: 20px;
    padding-left: 110px;
    padding-right: 20px;
}

.info-table {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    overflow: hidden;
}

.info-table th{
    /*background-color: #1386B3;*/
    background-color: var(--primary-color);
    color: white;
    padding-left: 10px;
    border-bottom: 1px solid #dee2e6;
}

.info-table td{
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-bottom: 1px solid #dee2e6;
}

.table-title {
    font-weight: bold;
}

.info-title {
    font-weight: bold;
}

.page-container {
    font-size: 0.95em;
    background-color: white;
    padding: 50px;
    min-width: 768px;
}

.page-body {
    padding: 20px;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    margin-top: 20px;
}

.doc-table td {
    height: 50px;
}