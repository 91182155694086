

.welcome-content {
    padding-left: 200px;
}

.welcome-h2 {
    font-size: 24px;
    color: #404040;
}

.welcome-p {
    font-size: 1.1em;
    line-height: 18px;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-bottom: 9px;
    color: #404040;
}

.welcome-li {
    line-height: 18px;
    color: #808080;
    margin-bottom: 8px;
}



.welcome-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40px;
    background-color: #00ace6;
    padding-top: 7px;
    margin-top: 50px;
    padding-left: 200px;
    padding-right: 200px;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
}